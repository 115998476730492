/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/global.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
var $ = require('jquery');
require('jquery.counterup');
require('timecircles');
//require('slick-carousel');

import WOW from 'wow.js';
import 'owl.carousel/dist/owl.carousel.min.js';
import 'slick-carousel/slick/slick.min.js';
import 'magnific-popup/dist/jquery.magnific-popup.min.js';

(function($) {
    "use strict";
    
    // sticky menu
    
    var header = $('.menu-sticky');
    if(header.length){    
        var win = $(window);
        win.on('scroll', function() {
            var scroll = win.scrollTop();
            if (scroll < 1) {
                header.removeClass("sticky");
            } else {
                header.addClass("sticky");
            }
        });
    }
    
    /*----------------------------
    wow js active
    ------------------------------ */
    new WOW().init();
    
    //window load
    $(window).on( 'load', function() {
        //rs menu
        if($(window).width() < 992) {
            $('.rs-menu').css('height', '0');
            $('.rs-menu').css('opacity', '0');
            $('.rs-menu-toggle').on( 'click', function(){
                $('.rs-menu').css('opacity', '1');
            });
        }
    });
    
    /*-------------------------------------
    OwlCarousel
    -------------------------------------*/
    var rscarousel = $('.rs-carousel');
    if(rscarousel.length){
        $('.rs-carousel').each(function() {
            var owlCarousel = $(this),
            loop = owlCarousel.data('loop'),
            items = owlCarousel.data('items'),
            margin = owlCarousel.data('margin'),
            stagePadding = owlCarousel.data('stage-padding'),
            autoplay = owlCarousel.data('autoplay'),
            autoplayTimeout = owlCarousel.data('autoplay-timeout'),
            smartSpeed = owlCarousel.data('smart-speed'),
            dots = owlCarousel.data('dots'),
            nav = owlCarousel.data('nav'),
            navSpeed = owlCarousel.data('nav-speed'),
            xsDevice = owlCarousel.data('mobile-device'),
            xsDeviceNav = owlCarousel.data('mobile-device-nav'),
            xsDeviceDots = owlCarousel.data('mobile-device-dots'),
            smDevice = owlCarousel.data('ipad-device'),
            smDeviceNav = owlCarousel.data('ipad-device-nav'),
            smDeviceDots = owlCarousel.data('ipad-device-dots'),
            smDevice2 = owlCarousel.data('ipad-device2'),
            smDeviceNav2 = owlCarousel.data('ipad-device-nav2'),
            smDeviceDots2 = owlCarousel.data('ipad-device-dots2'),
            mdDevice = owlCarousel.data('md-device'),
            mdDeviceNav = owlCarousel.data('md-device-nav'),
            mdDeviceDots = owlCarousel.data('md-device-dots');

            owlCarousel.owlCarousel({
                loop: (loop ? true : false),
                items: (items ? items : 4),
                lazyLoad: true,
                margin: (margin ? margin : 0),
                //stagePadding: (stagePadding ? stagePadding : 0),
                autoplay: (autoplay ? true : false),
                autoplayTimeout: (autoplayTimeout ? autoplayTimeout : 1000),
                smartSpeed: (smartSpeed ? smartSpeed : 250),
                dots: (dots ? true : false),
                nav: (nav ? true : false),
                navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
                navSpeed: (navSpeed ? true : false),
                responsiveClass: true,
                responsive: {
                    0: {
                        items: (xsDevice ? xsDevice : 1),
                        nav: (xsDeviceNav ? true : false),
                        dots: (xsDeviceDots ? true : false)
                    },
                    768: {
                        items: (smDevice ? smDevice : 3),
                        nav: (smDeviceNav ? true : false),
                        dots: (smDeviceDots ? true : false)
                    },
                    480: {
                        items: (smDevice2 ? smDevice : 2),
                        nav: (smDeviceNav2 ? true : false),
                        dots: (smDeviceDots2 ? true : false)
                    },
                    992: {
                        items: (mdDevice ? mdDevice : 4),
                        nav: (mdDeviceNav ? true : false),
                        dots: (mdDeviceDots ? true : false)
                    }
                }
            });

        });
    }
    
    /*-------------------------------------
    Latest News Slider jQuery activation code
    -------------------------------------*/    
    // Latest News
    var latestnewsslider = $('.latest-news-slider');
    if(latestnewsslider.length){
        $('.latest-news-slider').slick({
            slidesToShow: 1,
            // vertical: true,
            // verticalSwiping: true,
            slidesToScroll: 1,
            arrows: true,
            fade: false,
            asNavFor: '.latest-news-nav'
        });
    }
    
    var latestnewsnav = $('.latest-news-nav');
    if(latestnewsnav.length){
        $('.latest-news-nav').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            asNavFor: '.latest-news-slider',
            dots: false,
            centerMode: false,
            centerPadding: '0',
            focusOnSelect: true
        });
    }
    
    /*-------------------------------------
    Home page main Slider
    -------------------------------------*/
    // Declare Carousel jquery object
    var owl5 = $('#slider-five');
    if(owl5.length){
        // Carousel initialization
        owl5.owlCarousel({
            animateIn: 'fadeIn',
            items:1,
            autoplay:false,
            loop: true,
            dots: true,
            autoplayTimeout: 8000
        });
    }
    // add animate.css class(es) to the elements to be animated
    function setAnimation ( _elem, _InOut ) {
        // Store all animationend event name in a string.
        // cf animate.css documentation
        var animationEndEvent = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';

        _elem.each ( function () {
            var $elem = $(this);
            var $animationType = 'animated ' + $elem.data( 'animation-' + _InOut );

            $elem.addClass($animationType).one(animationEndEvent, function () {
                $elem.removeClass($animationType); // remove animate.css Class at the end of the animations
            });
        });
    }

    // Fired before current slide change
    owl5.on('change.owl.carousel', function(event) {
        var $currentItem = $('.owl-item', owl5).eq(event.item.index);
        var $elemsToanim = $currentItem.find("[data-animation-out]");
        setAnimation ($elemsToanim, 'out');
    });

    // Fired after current slide has been changed
    owl5.on('changed.owl.carousel', function(event) {
        var $currentItem = $('.owl-item', owl5).eq(event.item.index);
        var $elemsToanim = $currentItem.find("[data-animation-in]");
        setAnimation ($elemsToanim, 'in');
    });
    
    /*-------------------------------------
     jQuery Main Menu activation code
     --------------------------------------*/
    var navdropdown = $('.nav#dropdown');
        if(navdropdown.length){
        jQuery('nav#dropdown').meanmenu();
    }
    
    /*-------------------------------------
    Main Menu jQuery activation code
    -------------------------------------*/
    $(".main-menu ul a").on('click', function(e) {
            var link = $(this);
            var item = link.parent("li");
            if (item.hasClass("active")) {
                item.removeClass("active").children("a").removeClass("active");
            } else {
                item.addClass("active").children("a").addClass("active");
            }
            if (item.children("ul").length > 0) {
                var href = link.attr("href");
                link.attr("href", "#");
                setTimeout(function () { 
                    link.attr("href", href);
                }, 300);
                e.preventDefault();
            }
        })
        .each(function() {
            var link = $(this);
            if (link.get(0).href === location.href) {
                link.addClass("active").parents("li").addClass("active");
                return false;
        }
    });
    
    /*--------------------------
     ScrollTop init Activation Code
    ---------------------------- */
    $(window).scroll(function() {
        if ($(this).scrollTop() >= 50) {        
            $('#return-to-top').fadeIn(200);   
        } else {
            $('#return-to-top').fadeOut(200);   
        }
    });
    $('#return-to-top').on('click', function() {     
        $('body,html').animate({
            scrollTop : 0                       
        }, 500);
    });
    
    window.setTimeout(function() {
        $(".alert").fadeTo(1000, 0).slideUp(1000, function(){
            $(this).remove(); 
        });
    }, 5000);
    
    /*==========================================
       Image Popup
    ==========================================*/
    var imaggepoppup = $('.image-popup');
    if(imaggepoppup.length){
        $('.image-popup').magnificPopup({
            type: 'image',
            callbacks: {
                beforeOpen: function() {
                   this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure animated zoomInDown');
                }
            },
            gallery: {
                enabled: true
            }
        });
    }
    
    /*==========================================
       CountDownTimer
    ==========================================*/
    var CountTimer = $('.CountDownTimer');
    if(CountTimer.length){ 
        $(".CountDownTimer").TimeCircles({
            fg_width: 0.030,
            bg_width: 0.8,
            circle_bg_color: "#ffffff",
            time: {
                Days:{
                    color: "#fbc02d"
                },
                Hours:{
                    color: "#fbc02d"
                },
                Minutes:{
                    color: "#fbc02d"
                },
                Seconds:{
                    color: "#fbc02d"
                }
            }
        });	
    }
    
    /*==========================================
       AdminPage
    ==========================================*/
    
    $('.add-another-collection-widget').click(function (e) {
        var list = jQuery(jQuery(this).attr('data-list'));
        // Try to find the counter of the list or use the length of the list
        var counter = list.data('widget-counter') | list.children().length;

        // grab the prototype template
        var newWidget = list.attr('data-prototype');
        // replace the "__name__" used in the id and name of the prototype
        // with a number that's unique to your emails
        // end name attribute looks like name="contact[emails][2]"
        newWidget = newWidget.replace(/__name__/g, counter);
        // Increase the counter
        counter++;
        // And store it, the length cannot be used if deleting widgets is allowed
        list.data('widget-counter', counter);

        // create a new list element and add it to the list
        var newElem = jQuery(list.attr('data-widget-tags')).html(newWidget);
        addImageFormDeleteLink(newElem);
        newElem.appendTo(list);
    });
    
})(jQuery);

function addImageFormDeleteLink($imageFormLi) {
    var $removeFormButton = $("<button class='btn btn-danger btn-sm' type='button'>X</button>");
    $imageFormLi.append($removeFormButton);

    $removeFormButton.on('click', function(e) {
        // remove the li for the tag form
        $imageFormLi.remove();
    });
}
